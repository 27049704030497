import React from "react";
import { Helmet } from "react-helmet";
import PropTypes from "prop-types";

const Seo = ({ seo, meta }) => {
  const metaDescription = seo?.metaDesc;
  const seoTitle = seo?.title;
  const ogTitle = seo?.opengraphTitle;
  const ogDesc = seo?.opengraphDescription;
  const twitterTitle = seo?.twitterTitle;
  const twitterDesc = seo?.twitterDescription;
  const opengraphImage = seo?.opengraphImage?.publicUrl;
  const twitterImage = seo?.twitterImage?.publicUrl;

  return (
    <Helmet
      // htmlAttributes={{
      //   lang,
      // }}
      title={seoTitle}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: ogTitle ? ogTitle : seoTitle,
        },
        {
          property: `og:description`,
          content: ogDesc ? ogDesc : metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `og:image`,
          content: opengraphImage,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: twitterTitle ? twitterTitle : seoTitle,
        },
        {
          name: `twitter:description`,
          content: twitterDesc ? twitterDesc : metaDescription,
        },
        {
          name: `twitter:image`,
          content: twitterImage,
        },
      ].concat(meta)}
    />
  );
};

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
};

export default Seo;
