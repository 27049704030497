import React from "react";
import { graphql } from "gatsby";
import parse from "html-react-parser";
import slugify from "../helpers/slugify";
import Seo from "../components/global/Seo";

export default function Awards({ data }) {
  return (
    <div className="page-template-faqs">
      <Seo seo={data.wpPage.seo} />
      <div className="wrapper">
        <section className="intro">
          <h1 className="heading heading--x-large">FAQ's</h1>
          {parse(data.wpPage.acfFaqs.faqsIntroText)}
        </section>
        {data.wpPage.acfFaqs.faqs.map((faqSection) => (
          <section className="faq-section">
            <h2 className="heading heading--medium">
              {faqSection.faqSectionHeading}
            </h2>
            {faqSection.faq.map((faq) => (
              <article>
                <div className="question">{faq.question}</div>
                <div className="answer">{faq.answer}</div>
              </article>
            ))}
          </section>
        ))}
      </div>
    </div>
  );
}

export const AwardsQuery = graphql`
  query FaqsQuery {
    wpPage(title: { eq: "FAQ’s" }) {
      seo {
        metaDesc
        twitterTitle
        twitterDescription
        opengraphDescription
        opengraphTitle
        title
        opengraphImage {
          publicUrl
        }
        twitterImage {
          publicUrl
        }
      }
      acfFaqs {
        faqs {
          faq {
            answer
            question
          }
          faqSectionHeading
        }
        faqsIntroText
      }
    }
  }
`;
